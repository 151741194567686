.background {
    background-image: url('https://images.unsplash.com/photo-1456428746267-a1756408f782?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80');
    background-size: cover;
    background-position: center;
    height: 95vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.displayText {
  color: white
}